.award-new-section {
	padding: 120px 0 120px 0;
	width: 100%;
	position: relative;
	background-size: cover;
	background-position: center;
	min-height: 828px;

	position: relative;

	.banner-background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.container {
		position: relative;
		z-index: 100;
	}

	&:before {
		content: "";
		background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		position: relative;
		// background:
		//   radial-gradient(72.9% 71.51% at 48.96% 52.13%, rgba(2, 135, 230, 0.2) 0%, rgba(6, 137, 231, 0.2) 100%),
		//   url("../../../public/images/home/award-bg.webp"),
		//   rgba(212, 212, 212, 0.2) -1323.142px -606.12px / 204.375% 245.28% no-repeat;
		background-size: cover;
		background-position: center;
		min-height: 828px;

		&:before {
			content: "";
			background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
	}

	.cirtified-card {
		height: 180px;
		width: 100%;
		border-radius: 16px;
		border: 2px solid rgba(0, 134, 230, 0.20);
		background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
		box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 16px;

		>div {
			width: 100%;
		}

		>img {
			height: 140px;
			width: auto;
		}

		// img {
		//   height: auto !important;
		//   width: auto !important;
		// }
		.google-card-heading {
			p {
				color: #000000 !important;

				>img {
					width: 20px !important;
					height: 20px !important;
				}
			}

			>img {
				width: auto !important;
				height: 32px !important;
			}
		}

	}


	.card1 {
		.google-card-heading img {
			width: auto !important;
			height: 42px !important;
		}
	}

	.card2 {
		.google-card-heading img {
			width: auto !important;
			height: 28px !important;
		}
	}

	.card3 {
		.google-card-heading img {
			width: auto !important;
			height: 28px !important;
		}
	}

	.award-card {
		background: linear-gradient(317.72deg, #8ABC00 5.24%, #CCFA4E 137.29%);
		position: relative;

		&::before {
			content: "";
			background-image: url("../../../public/images/home/dot-pattern.png");
			width: 100%;
			height: 100%;
			background-size: 100% 100%;
			background-position: center;
			position: absolute;
			left: 0;
			top: 0;
		}

		&::after {
			content: "";
			background: radial-gradient(61.2% 56.03% at 50.82% 52.22%, #8ABC00 34.5%, rgba(138, 188, 0, 0.00) 100%);
			width: 100%;
			height: 100%;
			background-size: 100% 100%;
			background-position: center;
			position: absolute;
			left: 0;
			top: 0;
		}

		h3 {
			font-size: 40px;
			line-height: 150%;
			font-weight: 700;
			color: #fff;
			margin: 0;
			position: relative;
			z-index: 9;
		}
	}

	.google-card {
		height: 130px;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 40px;

		.google-card-heading {
			p {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 16px;
			}

			.award-card {
				background: linear-gradient(317.72deg, #8ABC00 5.24%, #CCFA4E 137.29%);
				position: relative;

				&::before {
					content: "";
					background-image: url("../../../public/images/home/dot-pattern.png");
					width: 100%;
					height: 100%;
					background-size: 100% 100%;
					background-position: center;
					position: absolute;
					left: 0;
					top: 0;
				}

				&::after {
					content: "";
					background: radial-gradient(61.2% 56.03% at 50.82% 52.22%, #8ABC00 34.5%, rgba(138, 188, 0, 0.00) 100%);
					width: 100%;
					height: 100%;
					background-size: 100% 100%;
					background-position: center;
					position: absolute;
					left: 0;
					top: 0;
				}

				h3 {
					font-size: 40px;
					line-height: 150%;
					font-weight: 700;
					color: #fff;
					margin: 0;
					position: relative;
					z-index: 9;
				}
			}

			.google-card {
				height: 130px;
				flex-direction: column;
				align-items: flex-start;
				padding-left: 40px;

				.google-card-heading {
					p {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						font-size: 16px;
						line-height: 150%;
						font-weight: 500;
						margin-bottom: 8px;
						color: #000;

						>img {
							margin-right: 4px;
							width: 20px !important;
						}
					}

					>img {
						width: 100px !important;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {
	.container {
		max-width: 1000px !important;
	}

	.award-new-section .cirtified-card {
		// height: 140px;
		height: 160px;
		/* Increased for tablets */
		width: auto;
	}

	.award-new-section .cirtified-card h3 {
		font-size: 30px;
		line-height: 40px;
	}

	.award-new-section .google-card {
		height: 100px;
	}

	.award-new-section .google-card .google-card-heading {
		width: 100%;
	}
}

@media (max-width: 1400px) {
	.award-new-section {
		min-height: auto;
	}

	.award-new-section .google-card {
		padding-left: 20px;
	}

	.award-new-section .cirtified-card {
		// height: 140px;
		height: 150px;
		/* Slightly smaller than large screens */
		width: auto;
	}

	.award-new-section .cirtified-card>img {
		max-width: 100px;
		max-height: 100px;
	}

	.award-new-section .google-card .google-card-heading img {
		max-width: 150px;
		max-height: 50px;
		width: auto !important;
	}

	.award-new-section .cirtified-card h3 {
		font-size: 30px;
		line-height: 40px;
	}

	.award-new-section .google-card {
		height: 105px;
	}

	.award-new-section .google-card .google-card-heading {
		width: 100%;
	}
}

@media (max-width: 1440px) {
	.award-new-section .google-card .google-card-heading {
		width: 100%;
	}

	.award-new-section .google-card .google-card-heading>img {
		max-width: 150px;
		// max-height: 35px;
		width: auto !important;
	}
}

@media (max-width: 1024px) {
	.award-new-section {
		padding: 60px 0 44px 0;
	}
}

@media (max-width: 992px) {
	.award-new-section {
		padding: 60px 0;
	}

	.award-new-section .cirtified-card {
		// height: 140px;
		height: 180px;
		/* Increased for better visibility on tablets */
		width: auto;
	}

	.award-new-section .cirtified-card>img {
		max-width: 110px;
		max-height: 110px;
	}

	.award-new-section .google-card {
		height: 130px;
	}

	.award-new-section .award-card h3 {
		font-size: 24px;
	}
}

@media (max-width: 575px) {
	.award-new-section .cirtified-card {
		// height: 120px;	
		height: 160px;
		/* Further increase for very small screens */
		width: auto;
	}

	.award-new-section .award-card h3 {
		font-size: 20px;
		line-height: 30px;
		text-align: center;
	}
}

@media (max-width: 767px) {

	// .award-new-section .cirtified-card {
	//     max-width: calc(50% - 10px);
	//     display: inline-flex;
	//     margin: 5px 5px;
	// }
	.award-new-section .cirtified-card>img {
		height: 200px;
		/* Larger for smaller screens */
		width: auto;
	}

	.award-new-section {
		padding: 40px 0 30px 0;
	}

	.ordersm-01 {
		order: -1;
	}
}

// new code

.theme-dark {
	.process-new-section {
		position: relative;
		// background-image: url("../../../public/images/home/award-bg.webp"), cover, no-repeat;
	}

	.container {
		position: relative;
		z-index: 99;
	}

	.process-new-section::before {
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.3;
		background: linear-gradient(122deg,
				#0091da -11.62%,
				rgba(0, 145, 218, 0.88) 8.53%,
				rgba(69, 168, 104, 0) 69.21%,
				#84bd00 100%);
		display: block;
		z-index: 2;
	}
}

@media (max-width: 479px) {	
.award-new-section .google-card .google-card-heading > img {
	max-width: 100%;
}
}
